import { Box } from "@mui/system";
import clsx from "clsx";
import NWONLetterN from "../../../../public/media/svg/letter-N.svg";
import NWONLetterOPositionLeft from "../../../../public/media/svg/letter-O-position-left.svg";
import NWONLetterOPositionRight from "../../../../public/media/svg/letter-O-position-right.svg";
import {
  LetterName,
  LetterPosition,
  NWONBackgroundLetterProps,
} from "./NWONBackgroundLetterInterfaces";
import { useNWONBackgroundLetterStyles } from "./NWONBackgroundLetterStyles";

const NWONBackgroundLetter = (
  props: NWONBackgroundLetterProps
): JSX.Element => {
  const { classes } = useNWONBackgroundLetterStyles(props);
  let letter;

  if (props.letter === LetterName.N) {
    letter = (
      <Box className={clsx(classes.wrapper, props.className)}>
        <NWONLetterN className={classes.letter} />
      </Box>
    );
  } else {
    if (props.position === LetterPosition.Left) {
      letter = (
        <Box className={clsx(classes.wrapper, props.className)}>
          <NWONLetterOPositionLeft className={classes.letter} />
        </Box>
      );
    } else {
      letter = (
        <Box className={clsx(classes.wrapper, props.className)}>
          <NWONLetterOPositionRight className={classes.letter} />
        </Box>
      );
    }
  }

  return letter;
};

export default NWONBackgroundLetter;
