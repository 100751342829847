import { Card } from "@mui/material";
import Link from "../Link";
import { NWONLinkedCardProps } from "./NWONLinkedCardInterfaces";
import { useNWONLinkedCardStyles } from "./NWONLinkedCardStyles";

export const NWONLinkedCard = (props: NWONLinkedCardProps): JSX.Element => {
  const { classes } = useNWONLinkedCardStyles();

  return (
    <Link href={props.href} className={classes.link}>
      <Card className={classes.card}>{props.children}</Card>
    </Link>
  );
};
