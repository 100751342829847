import { makeStyles } from "tss-react/mui";

export const useNWONSectionTitleStyles = makeStyles()((theme) => ({
  title: {
    textAlign: "center",
    marginBottom: theme.spacing(4),
    [theme.breakpoints.up("md")]: {
      maxWidth: "800px",
    },
    marginLeft: "auto",
    marginRight: "auto",
  },
}));
