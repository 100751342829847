import { makeStyles } from "tss-react/mui";

export const useNWONArrowStyles = makeStyles()((theme) => ({
  wrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: theme.spacing(2.5),
    height: theme.spacing(2.5),
    border: `0.15rem solid ${theme.palette.primary.main}`,
    borderRadius: "100%",
  },
  arrowIcon: {
    width: theme.spacing(1.5),
    height: theme.spacing(1.5),
    color: theme.palette.primary.main,
  },
}));
