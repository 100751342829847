import { makeStyles } from "tss-react/mui";
import {
  LetterName,
  LetterPosition,
  NWONBackgroundLetterProps,
} from "./NWONBackgroundLetterInterfaces";

export const useNWONBackgroundLetterStyles =
  makeStyles<NWONBackgroundLetterProps>()((theme, props) => ({
    wrapper: {
      overflow: props.letter === LetterName.N ? "hidden" : "visible",
      position: "absolute",
      right: props.position === LetterPosition.Right ? 0 : "auto",
      left: props.position === LetterPosition.Left ? 0 : "auto",
      pointerEvents: "none",
    },
    letter: {
      zIndex: -1,
      height:
        props.letter === LetterName.O ? theme.spacing(30) : theme.spacing(25),
      marginRight:
        props.letter === LetterName.N && props.position === LetterPosition.Right
          ? theme.spacing(-25)
          : 0,
      marginLeft:
        props.letter === LetterName.N && props.position === LetterPosition.Left
          ? theme.spacing(-25)
          : 0,
    },
  }));
