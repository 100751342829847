import { ComponentWithOptionalClassNameProps } from "../../../interfaces/reactComponentInterfaces";

export enum LetterName {
  O = "O",
  N = "N",
}

export enum LetterPosition {
  Left = "left",
  Right = "right",
}

export interface NWONBackgroundLetterProps
  extends ComponentWithOptionalClassNameProps {
  letter: LetterName;
  position: LetterPosition;
}
