import {
  CardContent,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { motion } from "framer-motion";
import Image from "next/image";
import { UrlParameter } from "../../../../config/GoogleAdConfiguration";
import {
  upToMediumDevices,
  upToSmallDevices,
} from "../../../helpers/clientSide/constants";
import { useGetUrlParameters } from "../../../hooks/urls/useGetUrlParameters";
import { useHasUrlParameter } from "../../../hooks/urls/useHasUrlParameter";
import { useUrlForProjectPage } from "../../../hooks/urls/useUrlForProjectPage";
import HtmlFromLangFile from "../../01-atoms/HtmlFromLangFile/HtmlFromLangFile";
import NWONArrow from "../../01-atoms/NWONArrow/NWONArrow";
import NWONHashtagWrapper from "../../01-atoms/NWONHashtagWrapper/NWONHashtagWrapper";
import { NWONLinkedCard } from "../../01-atoms/NWONLinkedCard/NWONLinkedCard";
import { NWONProjectCardProps } from "./NWONProjectCardInterfaces";
import { useNWONProjectCardStyles } from "./NWONProjectCardStyles";

const animationVariants = {
  initial: {
    y: -10,
    x: 70,
    rotate: -10,
  },
  hover: {
    y: -20,
    x: 80,
    rotate: -5,
    scale: 1.05,
  },
  inView: {
    y: -20,
    x: 80,
    rotate: -5,
    scale: 1.05,
  },
};

const NWONProjectCard = (props: NWONProjectCardProps): JSX.Element => {
  const { project } = props;
  const { classes } = useNWONProjectCardStyles();
  const url = useUrlForProjectPage()(props.project.name);
  const theme = useTheme();
  const isMobileDevice = useMediaQuery(theme.breakpoints.only("xs"));
  const isCtaStyle = useHasUrlParameter(UrlParameter.CtaStyle);
  const ctaStyle = useGetUrlParameters(UrlParameter.CtaStyle);

  return (
    <NWONLinkedCard href={isCtaStyle ? `${url}${ctaStyle}` : url}>
      <CardContent
        component={motion.div}
        initial="initial"
        whileHover={!isMobileDevice ? "hover" : undefined}
        whileInView={isMobileDevice ? "inView" : undefined}
        className={classes.card}
        whileTap={{ scale: 0.98 }}
        viewport={{
          amount: "all",
          margin: `20% 0% 0% 0%`,
        }}
      >
        <Grid
          container={true}
          item={true}
          className={classes.descriptionContainer}
        >
          <Grid container={true} className={classes.titleArrowContainer}>
            <Grid item={true} xs={9}>
              <Typography variant="h5" component="h3">
                <HtmlFromLangFile contentContainingHtml={project.title} />
              </Typography>
            </Grid>

            <Grid item={true} container={true} xs={3} className={classes.arrow}>
              <NWONArrow />
            </Grid>
          </Grid>

          <Grid item={true} xs={12} md={10}>
            <Typography variant="body1" className={classes.description}>
              {typeof project.description === "string" ? (
                <HtmlFromLangFile contentContainingHtml={project.description} />
              ) : (
                project.description
              )}
            </Typography>
          </Grid>

          {project.hashtags && typeof project.hashtags === "object" && (
            <Grid
              item={true}
              container={true}
              spacing={0.5}
              className={classes.hashtags}
            >
              {project.hashtags.map((hashtag, index) => {
                return (
                  // Disable ESLint for the next line to define the index as the key of the Grid
                  // eslint-disable-next-line react/no-array-index-key
                  <Grid item={true} key={index}>
                    <NWONHashtagWrapper hashtag={hashtag} />
                  </Grid>
                );
              })}
            </Grid>
          )}
        </Grid>

        <Grid container={true} item={true} className={classes.imageContainer}>
          <motion.div variants={animationVariants} className={classes.image}>
            <Image
              src={project.previewImage}
              alt="project image"
              placeholder="blur"
              layout="responsive"
              sizes={`${upToSmallDevices} 100vw, ${upToMediumDevices} 50vw, 33vw`}
            />
          </motion.div>
        </Grid>
      </CardContent>
    </NWONLinkedCard>
  );
};

export default NWONProjectCard;
