import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Box } from "@mui/material";
import { useNWONArrowStyles } from "./NWONArrowStyles";

const NWONArrow = (): JSX.Element => {
  const { classes } = useNWONArrowStyles();

  return (
    <Box className={classes.wrapper}>
      <ArrowForwardIcon className={classes.arrowIcon} />
    </Box>
  );
};

export default NWONArrow;
