import { makeStyles } from "tss-react/mui";

export const useNWONLinkedCardStyles = makeStyles()((theme) => ({
  link: {
    textDecoration: "none",
  },
  card: {
    cursor: "pointer",
    transition: "border-color 0.2s",
    ":hover": {
      borderColor: theme.palette.grey[900],
    },
  },
}));
