import { makeStyles } from "tss-react/mui";

export const useNWONProjectCardStyles = makeStyles()((theme) => ({
  card: {
    cursor: "pointer",
    transition: "border-color 0.2s",
    ":hover": {
      borderColor: theme.palette.grey[900],
    },
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
  },
  descriptionContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  titleArrowContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  arrow: {
    justifyContent: "flex-end",
    [theme.breakpoints.down("md")]: {
      marginBottom: theme.spacing(1),
    },
  },
  description: {
    marginBottom: theme.spacing(1.5),
  },
  hashtags: {
    flexDirection: "row",
    marginBottom: theme.spacing(1),
  },
  imageContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    height: "100%",
    marginBottom: theme.spacing(-3),
    [theme.breakpoints.only("sm")]: {
      marginBottom: theme.spacing(-5),
    },
  },
  image: {
    width: "100%",
    marginTop: `${theme.spacing(3)} !important`,
    [theme.breakpoints.only("sm")]: {
      marginTop: `${theme.spacing(5)} !important`,
    },
  },
}));
