import { Typography } from "@mui/material";
import HtmlFromLangFile from "../HtmlFromLangFile/HtmlFromLangFile";
import { NWONSectionTitleProps } from "./NWONSectionTitleInterfaces";
import { useNWONSectionTitleStyles } from "./NWONSectionTitleStyles";

export const NWONSectionTitle = (props: NWONSectionTitleProps): JSX.Element => {
  const { classes } = useNWONSectionTitleStyles();

  return (
    <Typography variant="h2" className={classes.title}>
      <HtmlFromLangFile contentContainingHtml={props.title} />
    </Typography>
  );
};
