import { Card, Typography } from "@mui/material";
import { NWONHashtagWrapperProps } from "./NWONHashtagWrapperInterfaces";
import { useNWONHashtagWrapperStyles } from "./NWONHashtagWrapperStyles";

const NWONHashtagWrapper = (props: NWONHashtagWrapperProps): JSX.Element => {
  const { classes } = useNWONHashtagWrapperStyles();

  return (
    <Card className={classes.wrapper}>
      <Typography variant="body2">#{props.hashtag}</Typography>
    </Card>
  );
};

export default NWONHashtagWrapper;
